import Header from "../../components/Account/HeaderImage";
import Footer from "../../components/Account/Footer";
// import {auth0Config,auth0Config_Legacy} from '../../components/auth0';

import { auth0Config, auth0Config_Legacy } from "../../components/auth0";
import React, { useEffect, useRef, useState, useContext } from 'react';
import { auth0Cred } from "../auth0Cred";
import { useForm } from 'react-hook-form';
import { $CommonServiceFn, $postServiceFn } from '../../components/network/Service';
import { $Service_Url } from '../../components/network/UrlPath';
import Captcha from "../../components/Account/reCaptcha/Captcha";
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import konsole from "../../components/control/Konsole";
import { UserContext } from '../../App';
import commonLib from "../../components/control/commonLib";
// import "./Univer.css"
import Success from "../../components/Account/Success";
import HeaderImage from "../../components/Account/HeaderImage";
import { errorMsg } from "../../components/control/Constant";
import CommonInputContainer from "../../components/CommonInputContainer";
import SessionTimeout from '../../components/SessionTimeout';

const SignIn = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [passwordType, setPasswordType] = useState('password');
  const { register, handleSubmit, watch, setError, setValue, clearErrors, formState: { errors } } = useForm();
  const { setLoader, setcurMainImageNum, setWarning } = useContext(UserContext);
  const captchaRef = useRef(null);
  const [userNameFromCookies, setUserNameFromCookies] = useState('');
  const [rememberUserNameStatus, setRememberUserNameStatus] = useState(false);
  const [rolesDetails, setRolesDetails] = useState([])
  const [checkforgetPassstatus, setcheckforgetPassstatus] = useState(false)
  const [sendforgetpassmail, setsendforgetpassmail] = useState('')
  const [upcomingScreenType, setUpcomingScreenType] = useState('')
  const [upcomingScreenSubtenant, setUpcomingScreenSubtenant] = useState('')
  const [subtenantId, setSubtenantId] = useState('')
  const [loginSuccess, setloginSuccess] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);


  const type = commonLib.getQueryFromUrl("type")
  const sessionTimeout = commonLib.getQueryFromUrl("expired")


  const subtenantDetails = commonLib.getSessionStorage("subtenantDetails");
  const userInvalidErrMsg="The username or password you entered is incorrect. Please try again."

  // use Location handle --------------------------------------------------------------------------
  useEffect(() => {
    konsole.log(sessionTimeout,"sdnshdsdsdjs")
    if (sessionTimeout) {
      setShowTimeoutModal(true);
    }
    let subtenantId = commonLib.getQueryFromUrl("subtenantId");
    if (subtenantId) {
      setSubtenantId(subtenantId);
    }
    else if (subtenantDetails !== null) {
      setSubtenantId(JSON.parse(subtenantDetails).subtenantId)
    }
    else {
      setSubtenantId(null);
    }
    if (state !== null) {
      setUpcomingScreenType(state?.type)
      setUpcomingScreenSubtenant(state?.subtenantId)
    }
  }, [sessionTimeout, state, subtenantDetails])

  konsole.log("subtenantId", subtenantId, state)
  // use Location handle --------------------------------------------------------------------------
  useEffect(() => {
    let rememberData = localStorage.getItem("userName")
    if (rememberData !== null && rememberData !== undefined && rememberData !== "") {
      konsole.log("rememberDatarememberData", rememberData)

      setValue("email", decodeURIComponent(rememberData))
      setUserNameFromCookies(setValue)
      // setRememberUserNameStatus1(true)
      setRememberUserNameStatus(true)
    }

    setcurMainImageNum(1);
  }, [])

  const onSubmit = (data) => {
    // data.preventDefault();
    konsole.log(data,"datasubmit")
    if(isValidate(data) == true) return;
    konsole.log(data,":datasubmit")

        // setLoader(true);
    //konsole.log("onSubmit data ", captchaRef.current);
    // let captchaValue = captchaRef.current.checkCaptcha();
    // if (captchaValue == null) {
    //   return;
    // }

    //konsole.log('data', data);
    const authCredential = {
      realm: auth0Cred.realm,
      username: data.emailId,
      password: data.password
    };


konsole.log(data.password,"data?.password", data.email)
    setLoader(true);
    localStorage.setItem("|\/*P*/\|", window.btoa(commonLib?.passwordformatAndReverse(data?.password)))
    $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.checkLoginUserExist + data.emailId, "", (resp, error) => {
      setLoader(false);
      konsole.log(resp, error,"resp, error")
      if (resp) {
        konsole.log(resp,"respresprespresp")
        if (rememberUserNameStatus == true) {
          // document.cookie = `userName=${encodeURIComponent(data.email)};path=http://localhost:3000/account/Signin`
          localStorage.setItem("userName", encodeURIComponent(data.emailId))

        }
        //konsole.log("response", resp);
        if (resp.status == 200 && resp.data.data == true) {
          setLoader(true)
          let checkroles = rolesDetails.some(item => item?.roleId === 20)
          konsole.log("checkrolescheckroles", checkroles)
          // let auth0Configfun=(checkroles==true)? auth0Config_Legacy: auth0Config
          auth0Config().login(authCredential, (err, authResult) => {
            // auth0Configfun().login(authCredential, (err, authResult) => {
            setLoader(false);
            konsole.log("sdbvjks", err)
            if (err) {
              setLoader(false);
              //konsole.warn("Error", err);
              if (err.description == "Wrong email or password.") {
                setError('invalidUser', { type: "custom", message: userInvalidErrMsg })
                // captchaRef.current.refreshCaptcha();
              } else {
                setError('invalidUser', { type: "custom", message: errorMsg.somethingErr })
              }
              return;
            }
            if (authResult) {
              //konsole.log("authResult", authResult);
              // window.origin = window.location.origin;
            }
          });
        }
      }
      else if (error) {
        setLoader(false)
        //konsole.log("error",error.response)
        // setError('invalidUser', { type: "custom", message: error.data.messages[0] })
        if(error?.data?.data == false) setError('invalidUser', { type: "custom", message: userInvalidErrMsg })
        else setWarning('warning', errorMsg.somethingErr)
        // captchaRef.current.refreshCaptcha();
      }
    })
  }


  const passwordToggle = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
    }
    else {
      setPasswordType('password')
    }
  }

  const handleWrongPass = (e) => {
    clearErrors('invalidUser');
    e.target.value = e.target.value.trimStart();
  }
  const rememberUserName = (e) => {
    setRememberUserNameStatus(e.target.checked)
    // setRememberUserNameStatus1(e.target.checked)
  }

  const handleOnBlur = (e) => {
    let value = e.target.value

    if (value == "" || !commonLib.isEmailRegEx(value)) {
      return;
    }




    konsole.log("value", value)
    setLoader(true)
    $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.getUserDetailsByEmailId + `?emailId=${value}`, "", (res, err) => {
      setLoader(false)
      if (res) {
        konsole.log("getUserDetailsByEmailId", res)
        let responseData = res?.data[0];
        let rolesDetails = responseData?.roleDetails;

        setRolesDetails(rolesDetails)
        let checkroleAcademy = rolesDetails?.some(item => item?.roleId === 17)
        let checkRoleLagecy = rolesDetails?.some(item => item?.roleId === 20)
        konsole.log("responseData", responseData, rolesDetails, checkroleAcademy)

        if (checkroleAcademy == true && responseData?.auth0Id == null) {
          setError('invalidUser', { type: "custom", message: "Please activate your account before signing in." })
          let userId = responseData?.userId
          let jsonobj = {
            "userRegstrtnId": responseData?.id,
            "userId": userId,
            "signUpPlatform": responseData?.signUpPlatform,
            "createdBy": userId
          }
          userActivationLinkfun(jsonobj)
        } else if (checkRoleLagecy == true && responseData?.auth0Id !== null && responseData?.isPwdAvailable !== true) {
          resetPassword(value)
        }
      } else {
        konsole.log("getUserDetailsByEmailId", err)
      }
    })
  }

  const resetPassword = (emailId) => {
    setsendforgetpassmail(emailId)
    setLoader(true)
    const jsonobj = {
      userName: emailId,
      emailID: emailId,
      requestedIP: "::1",
      commMedium: "Email",
      commSendOn: emailId
    }
    konsole.log("jsonobjjsonobj", JSON.stringify(jsonobj))
    $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postForgotPassword, jsonobj, (response, errData) => {
      if (response) {
        setLoader(false)
        setcheckforgetPassstatus(true)
        konsole.log("postForgotPassword", response)
      }
      else {
        setLoader(false)
        konsole.log("postForgotPassword", errData)
      }
    });

  }

  const userActivationLinkfun = (jsonobj) => {
    konsole.log("jsonobjjsonobj", JSON.stringify(jsonobj))
    setLoader(true)
    $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.getUserActivationLink, jsonobj, (res, err) => {
      setLoader(false)
      if (res) {
        konsole.log("getUserActivationLink", res)
        let responseData = res.data.data
        konsole.log("responseData", responseData, responseData?.activationURL)
        window.location.replace(responseData?.activationURL)

      } else {
        konsole.log("getUserActivationLink", err)
      }
    })
  }

  //handle register btn----------------------------------------------------------------
  const handleRegisterHere = () => {
    let subtenantIdLocal = upcomingScreenSubtenant ? upcomingScreenSubtenant : subtenantId
    if (subtenantIdLocal == 3) {
      navigate(`/Account/startfreetrial?subtenantId=${subtenantId}`)
    } else {
      navigate('/Account/Signup')
    }
  }


  const showCaptchaError = () => {
    let captchaValue = captchaRef.current?.checkCaptcha();
  }
  const inputfieldcapital = (val) => {
    // const valueenter = val.target.value?.replace(/^\s+/g, '');
    // const valueid = val.target.id;
    // let value = valueenter.charAt(0).toUpperCase() + valueenter.slice(1);
    // konsole.log(val.target.value, val.target.id)

    // if (valueid == 'firstName') {
    //   setFirstName(value)
    // } else {
    //   setlastName(value)
    // }
    val.target.value = val.target.value?.trimStart();
  }

  const isValidate = ( formData ) => {
    let isAnyError = false;
    const { emailId, password, } = formData;

    if(!emailId) {
      setError("emailId", { type: "error", message: errorMsg.emailId.empty })
      isAnyError = true
    } else if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailId) == false) {
      setError("emailId", { type: "error", message: errorMsg.emailId.invalid })
      isAnyError = true
    }

    if(!password) {
      setError("password", { type: "error", message: errorMsg.password.empty })
      isAnyError = true
    // } else if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@@#$%^&*])[A-Za-z\d!@@#$%^&*]{8,}$/.test(password) == false) {
    //   setError("password", { type: "error", message: errorMsg.password.invalid })
    //   isAnyError = true
    }
    return isAnyError;
  }

  const ErrorShower = ({ inputName }) => {
    konsole.log(inputName,"inputName")
    return errors?.[inputName]?.message ? <p className='attention'>{errors?.[inputName]?.message}</p> : "";
  }
konsole.log(errors,"errorserrorserrorserrors")
  //handle register btn----------------------------------------------------------------

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
    <div id='logIn'>
      <div className='headerImg'>
        <HeaderImage />
      </div>

      <div className="vertically-center">
        <div className="parentDiv">
        <p className='title'>Login</p>
        <p className='sub-title'>Welcome back! Please Enter Your Details</p>

        {errors?.invalidUser ? <p className='attention mb-4'>{errors?.invalidUser?.message}</p> : ""}
        {loginSuccess ? <p className='attention mb-4 text-success'>{"Login successful. Welcome back to Aging Options Portal!"}</p> : ""}
        <CommonInputContainer
          label="Email*"
          error={errors?.emailId}
        >
          <input className={`w-100 ${errors?.emailId ? 'input-error' : ''}`} type='email' placeholder='Enter email' onInput={handleWrongPass}
          {...register("emailId", {
            onChange: (e) => { inputfieldcapital(e) }
          })}/>
        </CommonInputContainer>

        <CommonInputContainer
          label="Password*"
          error={errors?.password}
        >
          <input type={passwordType} placeholder='Enter password' className={`w-100 ${errors?.password ? 'input-error' : ''}`}  onInput={handleWrongPass}
            {...register('password', {
              onChange: (e) => {inputfieldcapital(e) }
            })}
          />
          <img 
            src={(passwordType === 'password') ? '/icons/eye-off.svg' : '/icons/eye-show.svg'}
            className={`toggle-icon img-fluid cursor-pointer ${errors?.password ? 'error' : ''}`} 
            onClick={passwordToggle} 
            alt='toggle visibility'
          />
        </CommonInputContainer>

        <div className="label-input">
          <button type="submit">Login</button>
        </div>

        <div  className="other-links">
          <NavLink className="aTagElement" to="/Account/ForgetPassword">Forgot Password?</NavLink>
          <p>Don't have an account ? <NavLink className="aTagElement" to="/Account/Signup#">Sign Up</NavLink></p>
        </div>
        </div>
      </div>
    </div>
    {showTimeoutModal && <SessionTimeout />}
    </form>
  );
};

export default SignIn;
